import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <h1>見つかりませんでした</h1>
    <p>申し訳ありませんが、このURLでページがない。</p>
  </Layout>
);

export default NotFoundPage;
